import httprequest from "./httprequest";

// getters

const getAllAssets = () => {
  return httprequest.get("/api/MobileAssets/?fullList=true");
};

const getAssetByDialog = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/AssetDialog" + filters);
};

const getAssetList = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/Assets/" + params);
};

const getAssetStatuses = () => {
  return httprequest.get("/api/AssetRunningStatus");
};

const getAllAssetClassifications = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/AssetClassifications/all/" + params);
};

const getAllAssetCategories = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/AssetCatagories/all/" + params);
};

const getAssetFromCode = (code) => {
  return httprequest.get("/api/MobileAssets/" + code);
};
const getAssetFromCodeMain = (code) => {
  return httprequest.get("/api/UAT/Assets/Main/" + code);
};

const getAllAssetLogs = (code) => {
  return httprequest.get("/api/UAT/AssetLogs/?assetCode=" + code);
};

const getAllAssetRecentActivityLogs = (code) => {
  return httprequest.get(
    "/api/UAT/AssetLogs/RecentActivities/?assetCode=" + code
  );
};

const getAllUsers = () => {
  return httprequest.get("/api/MobileUser/?active=true");
};

const getLabourUsers = (plant) => {
  return httprequest.get("/api/MobileUser/?plant=" + plant);
};

const getLeads = () => {
  return httprequest.get("/api/MobileUser/?isLead=true");
};

const getAllPersonnelWithPlants = () => {
  return httprequest.get("/api/UAT/Personnel/?withPlants=true");
};

const getAllPersonnel = () => {
  return httprequest.get("/api/UAT/Personnel/");
};

const getAllPersonnelCrew = (crewId) => {
  return httprequest.get("/api/UAT/Personnel/?CrewId=" + crewId);
};

const getNonUserPersonnel = () => {
  return httprequest.get("/api/UAT/Personnel/?nonUsers=true");
};

const getAllSettingsUsers = () => {
  return httprequest.get("/api/UAT/User/?userDetails=true");
};

const getUserLimits = () => {
  return httprequest.get("/api/UAT/User/user-limit/");
};

const getAssertFromParentCode = (parentCode) => {
  return httprequest.get(`/api/MobileAssets/?parentCode=${parentCode}`);
};

const getAllWorkOrders = (payload) => {
  return httprequest.post("/api/MobileWorkOrder/list/", payload);
};

const getExistingWorkOrder = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/WorkOrder/existing/" + filters);
};

const getWorkOrderTypes = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/Workordertype/" + filters);
};

const getFailures = (params) => {
  return httprequest.get("/api/UAT/Failures/" + params);
};

const getAllFailureTypes = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/Failures/types" + filters);
};

const getAllFailureCauses = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/Failures/causes" + filters);
};

const getFilteredWorkOrderTypes = (assetCode) => {
  return httprequest.get(`/api/Workordertype/?assetCode=${assetCode}`);
};

const getMaintenanceTypes = (workOrderType, assetCode) => {
  return httprequest.get(
    `/api/MaintenanceType/?workOrderType=${workOrderType}&assetCode=${assetCode}`
  );
};

const getMaintenanceType = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/MaintenanceType/" + filters);
};

const getMaintenancePlant = (params) => {
  const param = params !== undefined ? params : "";
  return httprequest.get("/api/UAT/Warehouses/" + param);
};

const getMaintenancePlantByUser = (userId) => {
  return httprequest.get("/api/UAT/Warehouses/?userId=" + userId);
};

const getReplacementTypes = () => {
  return httprequest.get("/api/UAT/ReplacementType/?all=true");
};

const getRotations = () => {
  return httprequest.get("/api/UAT/Rotation/?all=true");
};

const getAllProjects = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get("/api/Projects/" + filters);
};

const getUserRoles = () => {
  return httprequest.get("/api/UAT/UserRoles/?display=true");
};

const getUserProfiles = () => {
  return httprequest.get("/api/UAT/Profile/");
};

const getOperationsfromgroup = (assetCode) => {
  return httprequest.get(`api/MobileTasks/?assetCode=${assetCode}`);
};

const getOperationList = (params) => {
  const filters = params !== undefined ? params : "";
  return httprequest.get(`api/MobileTasks/${filters}`);
};

const getOperationFailureTypes = (operationId) => {
  return httprequest.get(`api/OperationFailures/failureType/${operationId}`);
};

const getOperationFailureCauses = (operationId) => {
  return httprequest.get(`api/OperationFailures/failureCause/${operationId}`);
};

const getFailuresByOperation = (operationId, isCause, isType) => {
  return httprequest.get(
    `api/OperationFailures/?operationId=${operationId}&isCause=${isCause}&isType=${isType}`
  );
};

const getWorkOrderLabor = (workOrderCode) => {
  return httprequest.get(`api/MobileLabour/?workOrder=${workOrderCode}`);
};

const getWorkOrderParts = (workOrderCode) => {
  return httprequest.get(`api/WorkOrderResources/${workOrderCode}`);
};

const getAttachments = (rootFolder, id) => {
  return httprequest.get(
    `api/UAT/Attachment/?rootFolder=${rootFolder}&id=${id}`
  );
};
const getAttachmentFile = (attachmentId) => {
  return httprequest.get(`api/UAT/Attachment/${attachmentId}`, {
    responseType: "blob",
  });
};

const getWorkOrderAttachments = (workOrderCode) => {
  return httprequest.get(`api/UAT/Attachments/?workOrderCode=${workOrderCode}`);
};
const getWorkRequestAttachments = (workOrderCode) => {
  return httprequest.get(
    `api/UAT/WorkRequestAttachments/?workRequestID=${workOrderCode}`
  );
};
const getWorkOrderAttachmentFile = (attachmentId) => {
  return httprequest.get(`api/UAT/Attachments/${attachmentId}`, {
    responseType: "blob",
  });
};

const getWorkOrderFailure = (workOrderCode) => {
  return httprequest.get(`api/UAT/WorkOrderFailures/${workOrderCode}`);
};

const getWorkOrderFleet = (workOrderCode) => {
  return httprequest.get(
    `api/UAT/FleetTracker/?workOrderCode=${workOrderCode}`
  );
};

const getFleetTrackerSummary = (workOrderCode) => {
  return httprequest.get(`api/UAT/FleetTracker/display/${workOrderCode}`);
};

const getFleetData = (assetCategory, workOrderCode, workOrderId) => {
  return httprequest.get(
    `api/UAT/FleetTracker/data/?assetCategory=${assetCategory}&workOrderCode=${workOrderCode}&workOrderId=${workOrderId}`
  );
};

const getFleetTracker = (assetId) => {
  return httprequest.get(`api/FT/?assetId=${assetId}`);
};

const getWorkRequests = (userId) => {
  return httprequest.get(`api/UAT/WorkRequest/?userId=${userId}`);
};

const getWorkOrderMeterReadings = (assetCode, workOrderCode) => {
  return httprequest.get(
    `api/UAT/WorkOrderMeterReadings/?AssetCode=${assetCode}&WorkOrderCode=${workOrderCode}`
  );
};

const getMeterList = () => {
  return httprequest.get("/api/uat/Meter/?list=true");
};

const getMeter = (meterId) => {
  return httprequest.get("/api/uat/Meter/" + meterId);
};

const executeSSO = () => {
  return httprequest.get("/api/uat/ssoauthorize");
};

const consumeSSO = (Id) => {
  return httprequest.get("/api/UAT/ssoconsume/" + Id);
};
const GetAnalyticsDashboards = (AnalyticsCategory) => {
  return httprequest.get(
    `/api/UAT/AnalyticsDashboards/?AnalyticsCategory=${AnalyticsCategory}`
  );
};

const getCrewList = () => {
  return httprequest.get("/api/UAT/Crews/?crewList=true");
};

const getAllCrews = () => {
  return httprequest.get("/api/UAT/Crews/?getall=true");
};

const getCrewAssets = (crewName, crewId) => {
  return httprequest.get(
    `/api/UAT/CrewAssets/?crewname=${crewName}&crewid=${crewId}`
  );
};

const getCrewMembers = (Name, crewId) => {
  return httprequest.get(`/api/UAT/CrewMembers/?Name=${Name}&crewid=${crewId}`);
};

const getCrewAssetList = () => {
  return httprequest.get(`/api/UAT/Assets/?noCrew=true`);
};

const getInstallAssetList = (typename) => {
  return httprequest.get(`/api/UAT/Assets/?assetCategory=${typename}`);
};

const getSubComponentsParts = () => {
  return httprequest.get(`/api/UAT/FtsubComponentParts`);
};

const getSubComponentsAreas = () => {
  return httprequest.get(`/api/UAT/FtsubComponentAreas`);
};

const getFleetRotation = () => {
  return httprequest.get(`/api/UAT/Ftheader`);
};
const getDismantleWorkOrder = (workOrderCode) => {
  return httprequest.get(`/api/UAT/Rotables/?workOrderCode=` + workOrderCode);
};
const getAssets = (treeview, PageNumber, PageRows) => {
  return httprequest.get(
    `/api/UAT/Assets/?treeview=${treeview}&PageNumber=${PageNumber}&PageRows=${PageRows}`
  );
};
const getAssetGrid = () => {
  return httprequest.get("/api/UAT/Assets/?assetGrid=true");
};

const getSessionUser = () => {
  return httprequest.get(`/api/SessionUser`);
};

const getRequestNotes = (requestId) => {
  return httprequest.get(`/api/UAT/WorkNotes/?workRequestId=${requestId}`);
};
const getWorkOrderNotes = (workorder) => {
  return httprequest.get(`/api/UAT/WorkNotes/?workOrderId=${workorder}`);
};

const getPersonnelPlants = (personnelId) => {
  return httprequest.get(
    `/api/UAT/MultiPlantPersonnel/?personnelId=${personnelId}`
  );
};

const getPMComplianceTypes = () => {
  return httprequest.get("/api/PMComplianceTypes/?active=true");
};

const getRequestType = () => {
  return httprequest.get("/api/RequestType/");
};

const getSubTaskTypes = (params) => {
  return httprequest.get("/api/SubtaskTypes/" + params);
};

const getUserPlants = (userId) => {
  return httprequest.get(`/api/UAT/MultiPlantPersonnel/?userId=${userId}`);
};

const getWorkOrderSubTasks = (params) => {
  params = params !== undefined ? params : "";
  return httprequest.get("/api/WorkOrderChecklist/" + params);
};

const getTaskGroups = (active) => {
  return httprequest.get("/api/TaskGroups/?active=" + active);
};

const getTaskGroupCategories = (id) => {
  return httprequest.get("/api/TaskGroups/categories/" + id);
};

const getTaskGroupsTasks = (id) => {
  return httprequest.get("/api/TaskGroups/tasks/" + id);
};

const getTaskGroupsTask = (id) => {
  return httprequest.get("/api/TaskGroups/task/" + id);
};

const getUiKeys = () => {
  return httprequest.get("/api/UiKeys/");
};

const getWorkOrderHistoryLog = (workorder) => {
  return httprequest.get(`/api/UAT/WorkOrderLogs/${workorder}`);
};

const getWorkOrdersPrint = (ids) => {
  return httprequest.post("/api/WorkOrderPrint/", ids);
};

const getWorkOrderPrint = (id) => {
  return httprequest.get("/api/WorkOrderPrint/" + id);
};

const getWorkOrderForm = (workOrderId) => {
  return httprequest.get("/api/WorkCreation/" + workOrderId);
};

// post

const createAssetClassifications = (payload) => {
  return httprequest.post("/api/UAT/AssetClassifications/", payload);
};

const createFailureType = (payload) => {
  return httprequest.post("/api/UAT/Failures/types", payload);
};

const createFailureCause = (payload) => {
  return httprequest.post("/api/UAT/Failures/causes", payload);
};

const createOperationFailureTypes = (payload) => {
  return httprequest.post("api/OperationFailures/failureType/", payload);
};

const createOperationFailureCauses = (payload) => {
  return httprequest.post("api/OperationFailures/failureCause", payload);
};

const createWorkOrder = (payld) => {
  const payload = { ...payld };
  delete payload.estimatedDurationHours;
  delete payload.estimatedDurationMinutes;
  delete payload.jobDetails;
  delete payload.groupTaskDescription;

  return httprequest.post("/api/MobileWorkOrder/", payload);
};

const createNewWorkOrder = (payload) => {
  return httprequest.post("/api/WorkCreation/", payload);
};

const createWorkOrderRequest = (requestId, payld) => {
  const payload = { ...payld };
  delete payload.estimatedDurationHours;
  delete payload.estimatedDurationMinutes;
  delete payload.incidentDate;
  delete payload.jobDetails;
  delete payload.groupTaskDescription;

  return httprequest.post(
    "/api/UAT/HandleWorkRequest/?workRequestID=" + requestId,
    payload
  );
};
const createWorkOrderLabor = (payload) => {
  return httprequest.post("/api/MobileLabour/", payload);
};
const createWorkOrderAttachment = (woCode, fileName, payload, type) => {
  return httprequest.post(
    `/api/UAT/Attachments/?workOrderCode=${woCode}&fileName=${fileName}`,
    payload,
    {
      headers: {
        "Content-Type": type,
        description: new Date().toLocaleDateString(),
      },
    }
  );
};
const createAttachment = (rootFolder, id, fileName, payload, type) => {
  return httprequest.post(
    `/api/UAT/Attachment/?id=${id}&fileName=${fileName}`,
    payload,
    {
      headers: {
        "Content-Type": type,
        description: new Date().toLocaleDateString(),
        rootFolder: rootFolder,
      },
    }
  );
};

const createWorkRequestAttachment = (woCode, fileName, payload, type) => {
  return httprequest.post(
    `/api/UAT/WorkRequestAttachments/?workRequestID=${woCode}&fileName=${fileName}`,
    payload,
    {
      headers: {
        "Content-Type": type,
        description: new Date().toLocaleDateString(),
      },
    }
  );
};
const createPerson = (payload) => {
  payload.name = payload.firstName + " " + payload.lastName;
  return httprequest.post("/api/UAT/Personnel/", payload);
};

const createPersonnelPlant = (payload) => {
  return httprequest.post("/api/UAT/MultiPlantPersonnel/", payload);
};

const createUser = (payload) => {
  return httprequest.post("/api/UAT/User/", payload);
};

const createWorkOrderFailure = (payload) => {
  return httprequest.post("/api/UAT/WorkOrderFailures/", payload);
};

const createWorkOrderFleet = (payload) => {
  return httprequest.post("/api/UAT/FleetTracker/", payload);
};

const createWorkOrderSubTasks = (params, payload) => {
  params = params !== undefined ? params : "";
  return httprequest.post("/api/WorkOrderChecklist/" + params, payload);
};

const createWorkRequest = (payload) => {
  return httprequest.post("/api/UAT/WorkRequest/", payload);
};

const setEnvironment = (environment) => {
  return httprequest.post("/api/Environment/?environment=" + environment);
};

const createUserRole = (payload) => {
  return httprequest.post("/api/UAT/UserRoles/", payload);
};

const updateMeterReadings = (payload) => {
  return httprequest.post("/api/UAT/WorkOrderMeterReadings/", payload);
};

const createCrew = (payload) => {
  return httprequest.post("/api/UAT/Crews/", payload);
};

const createCrewAsset = (payload) => {
  return httprequest.post("/api/UAT/CrewAssets/", payload);
};

const createCrewMembers = (payload) => {
  return httprequest.post("/api/UAT/CrewMembers/", payload);
};

const createRequestOrderNotes = (note) => {
  return httprequest.post(`/api/UAT/WorkNotes/`, note);
};

const createDismantle = (payload) => {
  return httprequest.post(`/api/UAT/Rotables/`, payload);
};

const createAsset = (payload) => {
  return httprequest.post("/api/UAT/Assets/", payload);
};

const createMeter = (payload) => {
  return httprequest.post("/api/UAT/Meter/", payload);
};

const createTaskGroups = (payload) => {
  return httprequest.post("/api/TaskGroups/", payload);
};

const createTaskGroupsTask = (id, payload) => {
  return httprequest.post("/api/TaskGroups/newTask/" + id, payload);
};

const handleChecklist = (id, payload) => {
  return httprequest.post("/api/TaskGroups/task/newSubTask/" + id, payload);
};

const createTaskGroupsCategories = (id, payload) => {
  return httprequest.post("/api/TaskGroups/categories/" + id, payload);
};

// put

const updateAssetClassifications = (id, payload) => {
  return httprequest.put("/api/UAT/AssetClassifications/" + id, payload);
};

const updateFailureType = (id, payload) => {
  return httprequest.put("/api/UAT/Failures/types/" + id, payload);
};

const updateFailureCause = (id, payload) => {
  return httprequest.put("/api/UAT/Failures/causes/" + id, payload);
};

const retryWorkOrderSyncs = (id) => {
  return httprequest.put("/api/UAT/WorkOrderSyncRetry/" + id);
};

const updatePerson = (payload) => {
  payload.name = payload.firstName + " " + payload.lastName;
  return httprequest.put("/api/UAT/Personnel/" + payload.id, payload);
};

const updateTaskGroupsTask = (id, payload) => {
  return httprequest.put("/api/TaskGroups/task/" + id, payload);
};

const updateWorkOrderStatus = (payload) => {
  return httprequest.put("/api/UAT/WorkOrderStatus/", payload);
};

const updateUser = (payload) => {
  return httprequest.put("/api/UAT/User/" + payload.userId, payload);
};

const updateWorkRequest = (id, payload) => {
  return httprequest.put("/api/UAT/HandleWorkRequest/" + id, payload);
};
const updateWorkOrder = (id, payload) => {
  return httprequest.put("/api/UAT/WorkOrderDetailsUpdate/" + id, payload);
};
const updateUserRole = (payload) => {
  return httprequest.put("/api/UAT/UserRoles/" + payload.userRoleId, payload);
};

const updateCrew = (payload) => {
  return httprequest.put("/api/UAT/Crews/" + payload.crewId, payload);
};

const updateCrewStatus = (id) => {
  return httprequest.put("/api/UAT/Crews/statuschange/" + id);
};

const valideDismantle = (id, payload) => {
  return httprequest.put("/api/UAT/Rotables/" + id, payload);
};

const setAssetsFeaturedImage = (id, attachmentId) => {
  return httprequest.put(
    "/api/UAT/Assets/AssetPicture/" + id + "?attachmentID=" + attachmentId
  );
};

const updateAssetDetails = (id, payload) => {
  return httprequest.put("/api/UAT/Assets/Update/" + id, payload);
};

const validateAssetCode = (code) => {
  return httprequest.put("/api/UAT/Assets/validate/" + code);
};

const validateOperationGroupCode = (code, id) => {
  const groupId = id ? id : 0;
  return httprequest.put(
    "/api/TaskGroups/task-group-validate/" + code + "?groupId=" + groupId
  );
};

const validateOperationCode = (code, groupId, taskId) => {
  const tskId = taskId ? taskId : 0;
  return httprequest.put(
    "/api/TaskGroups/task-code-validate/" +
      code +
      "?groupId=" +
      groupId +
      "&taskId=" +
      tskId
  );
};

const updateMeters = (id, payload) => {
  return httprequest.put("/api/UAT/Meter/" + id, payload);
};

const reorderSubTasks = (payload) => {
  return httprequest.put("/api/TaskGroups/subtasks/reorder/", payload);
};

const updateTaskGroup = (id, payload) => {
  return httprequest.put("/api/TaskGroups/" + id, payload);
};

// DELETE

const deleteAsset = (payload) => {
  return httprequest.delete("/api/UAT/CrewAssets/", { data: payload });
};

const deleteCrew = (id) => {
  return httprequest.delete("/api/UAT/Crews/" + id);
};

const deleteMember = (id) => {
  return httprequest.delete("/api/UAT/CrewMembers/" + id);
};

const deletePersonnelPlant = (id) => {
  return httprequest.delete("/api/UAT/MultiPlantPersonnel/" + id);
};

const deleteSubtask = (id) => {
  return httprequest.delete("/api/TaskGroups/task/subTask/" + id);
};

const deleteTaskGroupCategory = (id) => {
  return httprequest.delete("/api/TaskGroups/categories/" + id);
};

const deleteTask = (id) => {
  return httprequest.delete("/api/TaskGroups/task/" + id);
};

const deleteTaskGroup = (id) => {
  return httprequest.delete("/api/TaskGroups/" + id);
};

const deleteOperationFailureTypes = (id) => {
  return httprequest.delete(`api/OperationFailures/failureType/${id}`);
};

const deleteOperationFailureCauses = (id) => {
  return httprequest.delete(`api/OperationFailures/failureCause/${id}`);
};

export default {
  getAllAssets,
  getAssetByDialog,
  getAssetList,
  getAssetGrid,
  getAssetStatuses,
  getAssertFromParentCode,
  getWorkOrderTypes,
  getAllUsers,
  getMaintenancePlant,
  getMaintenanceTypes,
  getMaintenanceType,
  getReplacementTypes,
  getRotations,
  getAllProjects,
  getOperationsfromgroup,
  getOperationList,
  getOperationFailureTypes,
  getOperationFailureCauses,
  getFailuresByOperation,
  createOperationFailureTypes,
  createOperationFailureCauses,
  deleteOperationFailureTypes,
  deleteOperationFailureCauses,
  getWorkOrderForm,
  createWorkOrder,
  createNewWorkOrder,
  getWorkOrderLabor,
  createWorkOrderLabor,
  getWorkOrderParts,
  getWorkOrderAttachments,
  getWorkOrderAttachmentFile,
  createWorkOrderAttachment,
  getAllPersonnel,
  createPerson,
  createPersonnelPlant,
  updatePerson,
  getWorkOrderFailure,
  createWorkOrderFailure,
  getAllSettingsUsers,
  getUserLimits,
  getFleetData,
  getFleetTracker,
  getWorkOrderFleet,
  getFleetTrackerSummary,
  createWorkOrderFleet,
  updateWorkOrderStatus,
  getUserRoles,
  createUser,
  updateUser,
  getNonUserPersonnel,
  getWorkRequests,
  createWorkRequest,
  setEnvironment,
  updateWorkRequest,
  createWorkOrderRequest,
  getAssetFromCode,
  getAllAssetClassifications,
  getAllAssetCategories,
  createAssetClassifications,
  updateAssetClassifications,
  createWorkRequestAttachment,
  getWorkRequestAttachments,
  getUserProfiles,
  createUserRole,
  updateUserRole,
  getWorkOrderMeterReadings,
  updateMeterReadings,
  executeSSO,
  consumeSSO,
  updateWorkOrder,
  GetAnalyticsDashboards,
  getAllCrews,
  createCrew,
  createCrewAsset,
  getCrewAssets,
  deleteAsset,
  deleteCrew,
  updateCrew,
  getCrewAssetList,
  getCrewMembers,
  createCrewMembers,
  deleteMember,
  deletePersonnelPlant,
  getAllPersonnelCrew,
  getSubComponentsAreas,
  getSubComponentsParts,
  getFleetRotation,
  getAssets,
  getSessionUser,
  getRequestNotes,
  getWorkOrderNotes,
  createRequestOrderNotes,
  retryWorkOrderSyncs,
  getLeads,
  getLabourUsers,
  getCrewList,
  getMaintenancePlantByUser,
  getPersonnelPlants,
  getUserPlants,
  getAllPersonnelWithPlants,
  updateCrewStatus,
  getDismantleWorkOrder,
  getInstallAssetList,
  createDismantle,
  valideDismantle,
  getAllAssetLogs,
  getFailures,
  getAllFailureTypes,
  getAllFailureCauses,
  createFailureType,
  createFailureCause,
  updateFailureType,
  updateFailureCause,
  getFilteredWorkOrderTypes,
  getAllWorkOrders,
  getExistingWorkOrder,
  getAttachments,
  createAttachment,
  getAttachmentFile,
  createAsset,
  createMeter,
  getAssetFromCodeMain,
  getMeterList,
  getAllAssetRecentActivityLogs,
  getMeter,
  setAssetsFeaturedImage,
  updateAssetDetails,
  validateAssetCode,
  validateOperationGroupCode,
  validateOperationCode,
  updateMeters,
  getWorkOrderHistoryLog,
  getWorkOrdersPrint,
  getWorkOrderPrint,
  getUiKeys,
  getTaskGroups,
  createTaskGroups,
  createTaskGroupsTask,
  getTaskGroupsTasks,
  getTaskGroupCategories,
  getTaskGroupsTask,
  updateTaskGroupsTask,
  updateTaskGroup,
  createTaskGroupsCategories,
  deleteTaskGroupCategory,
  deleteTask,
  deleteTaskGroup,
  getPMComplianceTypes,
  getRequestType,
  getSubTaskTypes,
  handleChecklist,
  deleteSubtask,
  reorderSubTasks,
  getWorkOrderSubTasks,
  createWorkOrderSubTasks,
};
